import { useVoerkaI18n } from "@voerkai18n/react";
import { useRequest } from "ahooks";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ApiRemainingPages,
  apiAnonymousUserLogin,
  apiHeartbeat,
  apiLoginWithGoogle,
  apiPasswordLogin,
  apiRegisterEmail,
  apiStartSession,
  apiPostUserAppLoginVerifyMicrosoftWeb,
  apiPostUserAppLoginVerifyAppleWeb
} from "../libs/http/api";
import deviceuuid from "../libs/deviceuuid";
import tokenManager from "../libs/TokenManager";
import { getRegion } from "../libs/http/http";
import { message } from "antd";
import { ct2Language } from "../libs/utils";
import { t } from "../languages";
import { logEvent } from "../libs/analytics";

export interface StatusChangeInfo {
  isLoggedLoading: boolean;
  message?: string; // 可选字段，用于提供额外信息
}

// 定义上下文数据类型
interface AuthContextType {
  isLoggedIn: boolean; // 是否登录
  isLocalLogin: boolean;
  count: number; //制作次数
  loginAuth: (ip_data: any) => void; // 设备号登录
  loginGoogle: (
    id_token: string,
    inviteCode: string | null,
    officialInviteCode: string | null
  ) => void; // google 登录
  loginApple: (
    id_token: string,
    inviteCode: string | null,
    officialInviteCode: string | null
  ) => void; // apple 登录
  loginMicrosoft: (
    id_token: string,
    inviteCode: string | null,
    officialInviteCode: string | null
  ) => void; // microsoft 登录
  updateCount: () => void; // 刷新制作次数
  logout: () => void; // 登出
  addStatusChangeListener: (listener: (info: StatusChangeInfo) => void) => void; // 添加监听器
  removeStatusChangeListener: (
    listener: (info: StatusChangeInfo) => void
  ) => void; // 移除监听器
  registerEmail: (
    email: string,
    code: string,
    password: string,
    inv_code: string | null,
    official_invite_code: string | null
  ) => Promise<boolean>;
  loginWithEmail: (email: string, password: string) => Promise<boolean>;
}

// 创建上下文
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// 提供者组件
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const statusChangeListeners = useRef<((info: StatusChangeInfo) => void)[]>(
    []
  );

  const [heartSession, setHeartSession] = useState(-1); //心跳
  const [count, setCount] = useState(0); // 剩余次数
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false); // 是否登录
  const [isLocalLogin, setLocalLogin] = useState<boolean>(true); // 是否本地登录
  const { activeLanguage } = useVoerkaI18n(); // 获取语言
  const loginApi = useRequest(apiAnonymousUserLogin, { manual: true });
  const googleApi = useRequest(apiLoginWithGoogle, { manual: true });
  const microsoftApi = useRequest(apiPostUserAppLoginVerifyMicrosoftWeb,{ manual: true})
  const registerApi = useRequest(apiRegisterEmail, { manual: true });
  const emailLoginApi = useRequest(apiPasswordLogin, { manual: true });
  const sessionApi = useRequest(apiStartSession, { manual: true });
  const hertApi = useRequest(apiHeartbeat, { manual: true });
  const apiPagesCount = useRequest(ApiRemainingPages, { manual: true });
  const appleApi = useRequest(apiPostUserAppLoginVerifyAppleWeb, { manual: true})
  const { runAsync: login } = loginApi;
  const { runAsync: googleLogin } = googleApi;
  const { runAsync: emailRegister } = registerApi;
  const { runAsync: emailLogin } = emailLoginApi;
  const { runAsync: microsoftLogin} = microsoftApi;
  const { runAsync: appleLogin} = appleApi
  

  const notifyStatusChange = (info: StatusChangeInfo) => {
    statusChangeListeners.current.forEach((listener) => listener(info));
  };

  const addStatusChangeListener = useCallback(
    (listener: (info: StatusChangeInfo) => void) => {
      statusChangeListeners.current.push(listener);
    },
    []
  );

  const removeStatusChangeListener = useCallback(
    (listener: (info: StatusChangeInfo) => void) => {
      statusChangeListeners.current = statusChangeListeners.current.filter(
        (l) => l !== listener
      );
    },
    []
  );

  const loginAuth = async (ip_data?: any) => {
    login({
      language: activeLanguage ?? "en",
      device_fingerprint: deviceuuid(),
      ip_address: ip_data?.ip,
      country: ip_data?.country,
    })
      .then((response) => {
        tokenManager.saveToken(response.data.data.access_token);
        setLocalLogin(true);
        logEvent("Home", "local_login");
        console.log("登录成功");

        // sessionApi.runAsync().then(resp => {
        //   if(resp.data.data.session_id){
        //     setHeartSession(resp.data.data.session_id)
        //   }
        // }).catch(err=>{})
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //微软 登录
  const loginMicrosoft = async (
    id_token: string,
    inv_code: string | null,
    official_invite_code: string | null
  ) => {
    notifyStatusChange({ isLoggedLoading: true, message: "正在登录" }); // 更改HomeHeader状态
    const ip_data = await getRegion();
    try {
      var response = await microsoftLogin({
        id_token: id_token,
        language: activeLanguage ?? "en",
        device_fingerprint: deviceuuid(),
        ip_address: ip_data.ip,
        country: ip_data.country,
        invite_code: inv_code,
        official_invite_code: official_invite_code,
      });
      notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
      if (response.data.code === 200) {
        tokenManager.saveGoogleToken(id_token);
        tokenManager.saveToken(response.data.data.access_token);
        setIsLoggedIn(true);
        logEvent("Home", "Microsoft_login");
        return true;
      } else {
        message.error("Network error!");
      }
    } catch {
      localStorage.removeItem("google");
      notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
      //logout()
      message.info(t("登录已过期，请重新登录！"));
    }
    return false;
  };

//apple 登录
const loginApple = async (
  id_token: string,
  inv_code: string | null,
  official_invite_code: string | null
) => {
  notifyStatusChange({ isLoggedLoading: true, message: "正在登录" }); // 更改HomeHeader状态
  const ip_data = await getRegion();
  try {
    var response = await appleLogin({
      id_token: id_token,
      language: activeLanguage ?? "en",
      device_fingerprint: deviceuuid(),
      ip_address: ip_data.ip,
      country: ip_data.country,
      invite_code: inv_code,
      official_invite_code: official_invite_code,
    });
    notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
    if (response.data.code === 200) {
      tokenManager.saveGoogleToken(id_token);
      tokenManager.saveToken(response.data.data.access_token);
      setIsLoggedIn(true);
      logEvent("Home", "Apple_login");
      return true;
    } else {
      message.error("Network error!");
    }
  } catch {
    localStorage.removeItem("google");
    notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
    //logout()
    message.info(t("登录已过期，请重新登录！"));
  }
  return false;
};

//google 登录
const loginGoogle = async (
  id_token: string,
  inv_code: string | null,
  official_invite_code: string | null
) => {
  notifyStatusChange({ isLoggedLoading: true, message: "正在登录" }); // 更改HomeHeader状态
  const ip_data = await getRegion();
  try {
    var response = await googleLogin({
      id_token: id_token,
      language: activeLanguage ?? "en",
      device_fingerprint: deviceuuid(),
      ip_address: ip_data.ip,
      country: ip_data.country,
      invite_code: inv_code,
      official_invite_code: official_invite_code,
    });
    notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
    if (response.data.code === 200) {
      tokenManager.saveGoogleToken(id_token);
      tokenManager.saveToken(response.data.data.access_token);
      setIsLoggedIn(true);
      logEvent("Home", "google_login");
      return true;
    } else {
      message.error("Network error!");
    }
  } catch {
    localStorage.removeItem("google");
    notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
    //logout()
    message.info(t("登录已过期，请重新登录！"));
  }
  return false;
};

  //邮箱注册登录
  const registerEmail = async (
    email: string,
    verification_code: string,
    password: string,
    inv_code: string | null,
    official_invite_code: string | null
  ) => {
    notifyStatusChange({ isLoggedLoading: true, message: "正在登录" });
    const ip_data = await getRegion();
    try {
      var response = await emailRegister({
        language: activeLanguage ?? "en",
        device_fingerprint: deviceuuid(),
        ip_address: ip_data.ip,
        country: ip_data.country,
        email: email,
        verification_code: verification_code,
        invite_code: inv_code,
        official_invite_code: official_invite_code,
        password: password,
      });
      if (response.data.code === 200) {
        notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
        tokenManager.saveEmail(email);
        tokenManager.saveEmailPwd(password);
        tokenManager.saveToken(response.data.data.access_token);
        setIsLoggedIn(true);
        logEvent("Home", "email_register");
        return true;
      }
    } catch {
      notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
      return false;
    }
    return false;
  };

  //邮箱登录
  const loginWithEmail = async (email: string, password: string) => {
    try {
      notifyStatusChange({ isLoggedLoading: true, message: "正在登录" });
      var response = await emailLogin(email, password);
      if (response.data.code === 200) {
        tokenManager.saveEmail(email);
        tokenManager.saveEmailPwd(password);
        localStorage.setItem('token',response.data.data.access_token)
        setIsLoggedIn(true);
        notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
        logEvent("Home", "email_login");
        return true;
      }
    } catch {
      notifyStatusChange({ isLoggedLoading: false, message: "登录结束" });
      return false;
    }
    return false;
  };

  //刷新消耗次数
  const updateCount = () => {
    apiPagesCount
      .runAsync()
      .then((resp) => {
        if (resp.data.code === 200) {
          setCount(resp.data.data.remaining_pages);
        }
      })
      .catch((err) => {});
  };

  const logout = () => {
    // 在这里处理登出逻辑，例如清除登录状态或者 token 等
    logEvent("Home", "login_out");
    setIsLoggedIn(false);
    tokenManager.removeToken();
  };

  const onStatusChange = useCallback((info: StatusChangeInfo): void => {
    alert(info);
  }, []);

  //判断是否登录，如果登录获取制作次数
  useEffect(() => {
    if (isLoggedIn || isLocalLogin) {
      updateCount();
    }
  }, [isLoggedIn, isLocalLogin]);

  //设置语言
  useEffect(() => {
    try {
      const id_token = tokenManager.getGoogleToken();
      const email = tokenManager.getEmail();
      const pwd = tokenManager.getEmailPwd();
      const setLanguage = async () => {
        const ip_data = await getRegion();

        if (id_token && !isLoggedIn) {
          loginGoogle(id_token, null, null);
        }else if (email && pwd && !isLoggedIn) {
          loginWithEmail(email, pwd);
        }
         else if (!isLocalLogin) {
          loginAuth(ip_data);
        }
        VoerkaI18n.change(ct2Language(ip_data.country));
      };
      setLanguage();
    } catch {
      VoerkaI18n.change("en");
    }
  }, []);

  //设置定时器
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (heartSession > -1) {
      interval = setInterval(() => {
        try {
          hertApi.runAsync(heartSession);
        } catch (e) {}
      }, 30000); // 每秒更新一次
    }

    // 清除定时器
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [heartSession]); // 当 isRunning 改变时重新运行

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLocalLogin,
        count,
        updateCount,
        loginAuth,
        logout,
        loginGoogle,
        addStatusChangeListener,
        removeStatusChangeListener,
        registerEmail,
        loginWithEmail,
        loginApple,
        loginMicrosoft
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// 自定义 hook 以方便在组件中使用上下文
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
