import Layout from "antd/es/layout/layout";
import HomeFooter from "../Home/Footer/HomeFooter";
import HomeHeader from "../Home/Header/HomeHeader";
import { apiArticleList } from "../../libs/http/api";
import { useRequest } from "ahooks";
import styled from "styled-components";
import { Flex } from "antd";
import { isMobile, px2vw } from "../../libs/utils";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useVoerkaI18n } from "@voerkai18n/react";
import { t } from "../../languages";
import { useParams } from "react-router-dom";
import map_detail_img_1 from "../../assets/scenes/map_detail_img_1@2x.png";
import map_detail_img_2 from "../../assets/scenes/map_detail_img_2@2x.png";
import map_detail_img_3 from "../../assets/scenes/map_detail_img_3@2x.png";
import map_detail_img_4 from "../../assets/scenes/map_detail_img_4@2x.png";
import map_detail_img_5 from "../../assets/scenes/map_detail_img_5@2x.png";
import map_detail_img_7 from "../../assets/scenes/map_detail_img_7@2x.png";
import map_detail_img_8 from "../../assets/scenes/map_detail_img_8@2x.png";
import map_detail_img_9 from "../../assets/scenes/map_detail_img_9@2x.png";
import map_detail_img_10 from "../../assets/scenes/map_detail_img_10@2x.png";
import map_detail_img_11 from "../../assets/scenes/map_detail_img_11@2x.png";
import map_detail_img_12 from "../../assets/scenes/map_detail_img_12@2x.png";
import map_detail_img_13 from "../../assets/scenes/map_detail_img_13@2x.png";
import map_detail_img_14 from "../../assets/scenes/map_detail_img_14@2x.png";
import { useUpload } from "../../components/FileUploadContext";
import React, { useRef } from "react";
import withMeta from "../../components/withMeta";
import { logEvent } from "../../libs/analytics";


export interface ContentItem {
  title: string;
  content: JSX.Element;
}

export interface ContentDictionary {
  [key: string]: ContentItem;
}

export default function PdfSmaller() {

  
  const { addFiles } = useUpload();
  const { id } = useParams();
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      logEvent("Home", "file_upload", "upload");
      addFiles(Array.from([file]));
      navigate("/historical");
    }
  };

  const contentDictionary: ContentDictionary = {
    "0": {
      title: t("图书转文字"),
      content: (
        <Flex vertical>
          <ContentFlex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("减少95%存储成本")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("通过图片转文字进行存储")}</b> <br />
                {t("90MB轻松转为6MB")}
                <br />
                {t("瘦身文件简简单单")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_13} alt={t("图片转文字节省空间示例图")}></ContentImage>
          </ContentFlex>
          <ContentFlex>
            <ContentImage order={9} src={map_detail_img_14} alt={t("图片转文字存储更多示例图")}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("你可以存更多")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("存储更多，看见更多")}
                <br />
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </ContentFlex>
        </Flex>
      ),
    },
    "1": {
      title: t("LLMs企业/学术研究机构"),
      content: (
        <Flex vertical>
          <Flex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("增强你的数据集")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("比竞争对手更完备")}</b> <br />
                {t("扫描件也可以成为模型可阅读的数据集")}
                <br />
                {t("古早文档统统收入囊中")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_1}></ContentImage>
          </Flex>
          <Flex>
            <ContentImage src={map_detail_img_2}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>{t("有效=高效")}</ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                <b>{t("只做有效的事")}</b> <br />
                {t("节省时间、财力")}
                <br />
                {t("把耗时耗力的重复性工作安心交给我们处理")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </Flex>
        </Flex>
      ),
    },
    "2": {
      title: t("电子书企业"),
      content: (
        <Flex vertical>
          <Flex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("降低95%成本")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                {t("快速促进书籍、档案数字化")}
                <br />
                {t("节省存储、传输")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_3}></ContentImage>
          </Flex>
          <Flex>
            <ContentImage src={map_detail_img_4}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("更好服务用户")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("给用户最快速提供最高清的图书")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </Flex>
        </Flex>
      ),
    },
    "3": {
      title: t("电子书企业"),
      content: (
        <Flex vertical>
          <Flex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("降低95%成本")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("节省存储成本")}</b>
                <br />
                {t("小小的移动设备容纳更多书籍")}
                <br />
                {t("再也不必担心存储空间")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_5}></ContentImage>
          </Flex>
          <Flex>
            <ContentImage src={map_detail_img_4}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>{t("对自己更好")}</ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("清晰的阅读，美好的体验")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </Flex>
        </Flex>
      ),
    },
    "4": {
      title: t("学生/老师"),
      content: (
        <Flex vertical>
          <Flex>
            <ContentTextBox>
              <ContentTextBoxTitle>
                {t("课件/文献语言障碍不复存在")}
              </ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("扫描件变文本件")}</b>
                <br />
                {t("直接翻译就好啦")}
                <br />
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_7}></ContentImage>
          </Flex>
          <Flex>
            <ContentImage src={map_detail_img_8}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>{t("DDL杀手")}</ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("快速复制引用，论文无忧")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </Flex>
        </Flex>
      ),
    },
    "5": {
      title: t("残障人士"),
      content: (
        <Flex vertical>
          <Flex>
            <ContentTextBox>
              <ContentTextBoxTitle>
                {t("让所有文件信息都能被读取")}
              </ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("扫描件转换文本后即可成为能够被语音阅读的文件")}</b>
                <br />
                {t("让所有文件变成无障碍")}
                <br />
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_9}></ContentImage>
          </Flex>
          <Flex>
            <ContentImage src={map_detail_img_10}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("关心在身边的他/她们")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("如果你希望为无障碍文件作出贡献")} <br />
                {t("非常欢迎联系我们")}
                <br />
                {t("我们将收录您的书籍，用于公益事业")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </Flex>
        </Flex>
      ),
    },
    "6": {
      title: t("语言学习者"),
      content: (
        <Flex vertical>
          <Flex>
            <ContentTextBox>
              <ContentTextBoxTitle>{t("快速提升语言能力")}</ContentTextBoxTitle>
              <ContentTextBoxTxt>
                <b>{t("扫描件变文本件")}</b>
                <br />
                {t("直接翻译就好啦")}
                <br />
                {t("多种语言自由切换阅读，不必再浪费过多时间查阅")}
              </ContentTextBoxTxt>
            </ContentTextBox>
            <ContentImage src={map_detail_img_11}></ContentImage>
          </Flex>
          <Flex>
            <ContentImage src={map_detail_img_12}></ContentImage>
            <SizedBox />
            <ContentTextBoxEnd>
              <ContentTextBoxTitleEnd>
                {t("你可以学习更多")}
              </ContentTextBoxTitleEnd>
              <ContentTextBoxTxtEnd>
                {t("看见更多语种，看见更多可能")}
              </ContentTextBoxTxtEnd>
            </ContentTextBoxEnd>
          </Flex>
        </Flex>
      ),
    },
  };

  return (
    <>
      {withMeta({
        title: t("上传PDF扫描件，轻松转换文本——高效PDF转文字工具"),
        description: t(
          "上传文件，通过OCR技术快速识别pdf扫描件内容，提取结果精准度高达99%＋。PDF扫描件内容提取后，点击下载即可获得pdf文本格式文件。"
        ),
        keywords:
          "pdf to pdf conversion，pdf convert to word converter，ocr text recognition，image pdf to pdf converter，free online OCR，compress pdf online",
      })}
      <Layout style={{ background: "#FCF6E8", alignItems: "center" }}>
        <HomeHeader />
        <Content>
          <Title>{contentDictionary[id ?? "0"].title}</Title>
          {isMobile() ? <Line /> : null}
          {contentDictionary[id ?? "0"].content}

          {isMobile() ? (
            <UploadButton onClick={() => {fileInputRef.current?.click()}}>{t("上传文件")}</UploadButton>
          ) : (
            <MoreButton
              onClick={() => {
                fileInputRef.current?.click();
              }}
            >
              {t("上传文件")}
            </MoreButton>
          )}
        </Content>
        <HomeFooter />
      </Layout>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </>
  );
}

const ContentFlex = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SizedBox = styled.div`
  width: 100px;
`;

const Line = styled.div`
  width: 1818px;
  height: 5px;
  background: #ebebeb;
  margin-top: 154px;
`;

const Content = styled.div`
  padding-left: 200px;
  padding-right: 200px;
  margin-top: 60px;
  margin-bottom: 100px;
  width: 1520px;
  height: 1269px;
  background: #ffffff;
  border-radius: 24px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    height: 1269px;
    height: auto;
    margin-top: 0px;
    border-radius: 82px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Title = styled.div`
  margin-top: 60px;
  font-family: Montserrat Blod;
  font-weight: bold;
  font-size: 48px;
  color: #222222;
  line-height: 71px;
  text-align: center;
  font-style: normal;
  text-transform: none;

  @media only screen and (max-width: 768px) {
    font-size: 123px;
    line-height: 148px;
    margin-top: 154px;
  }
`;
const ContentTextBox = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 102px;
    padding-right: 102px;
    align-items: start;
    margin-top: 154px;
  }
`;

const ContentTextBoxEnd = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 102px;
    padding-right: 102px;
    align-items: center;
    margin-top: 154px;
  }
`;

const ContentTextBoxTitle = styled.div`
  font-family: Montserrat Blod;
  font-weight: bold;
  font-size: 42px;
  color: #222222;
  line-height: 62px;
  text-align: left;
  font-style: normal;
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    font-size: 113px;
    line-height: 138px;
    white-space: nowrap;
  }
`;

const ContentTextBoxTitleEnd = styled.div`
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 42px;
  color: #222222;
  line-height: 62px;
  text-align: end;
  font-style: normal;
  white-space: nowrap;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 113px;
    line-height: 138px;
    white-space: nowrap;
    text-align: start;
  }
`;

const ContentTextBoxTxt = styled.div`
  margin-top: 24px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 48px;
  text-align: left;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    font-size: 77px;
    line-height: 123px;
    margin-top: 42px;
  }
`;

const ContentTextBoxTxtEnd = styled.div`
  margin-top: 24px;
  font-family: Montserrat Regular;
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  line-height: 48px;
  text-align: end;
  font-style: normal;

  @media only screen and (max-width: 768px) {
    width: 100%;
    font-size: 77px;
    line-height: 123px;
    white-space: nowrap;
    text-align: start;
  }
`;

const ContentImage = styled.img<{ order?: number }>`
  width: 480px;
  height: 480px;

  @media only screen and (max-width: 768px) {
    width: 1229px;
    height: 1229px;
    order: ${(props) => (props.order ? props.order : 0)};
  }
`;

const MoreButton = styled.button`
  width: 420px;
  background: #fdc448;
  height: 68px;
  font-size: 24px;
  box-shadow: 6px 6px 0px 0px #373737;
  border-radius: 16px;
  border: none;
  cursor: pointer;
`;

const UploadButton = styled.button`
  position: fixed;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 819px;
  background: #fdc448;
  height: 246px;
  font-size: 82px;
  box-shadow: 31px 31px 0px 0px #373737;
  border-radius: 41px;
  border: none;
  cursor: pointer;
  color: #333333;
`;