import React, { CSSProperties } from "react";
import type { CollapseProps, DrawerProps } from "antd";
import { Collapse, Drawer, Flex } from "antd";
import styled from "styled-components";
import logo from "../../../assets/logo@2x.png";
import h5_close from "../../../assets/home/mobile/h5_nav_more@2x.png";
import type { DrawerStyles } from "antd/es/drawer/DrawerPanel";
import { t } from "../../../languages";
import { useNavigate } from "react-router-dom";
import icon_llms from "../../../assets/home/home_use_icon_llms@2x.png";
import home_use_icon_company from "../../../assets/home/home_use_icon_company@2x.png";
import home_use_icon_read from "../../../assets/home/home_use_icon_read@2x.png";
import home_use_icon_student from "../../../assets/home/home_use_icon_student@2x.png";
import home_use_icon_body from "../../../assets/home/home_use_icon_body@2x.png";
import home_use_icon_ab from "../../../assets/home/home_use_icon_ab@2x.png";
import BlogBox from "../components/BlogBox";
import { logEvent } from "../../../libs/analytics";
import arrow from "../../../assets/home/arrow.png";
import nav_more_sel from "../../../assets/home/mobile/nav_more_sel@2x.png";
import { useVoerkaI18n } from "@voerkai18n/react";
import { useAuth } from "../../../components/AuthContext";

const { Panel } = Collapse;

interface HeaderDrawerProps {
  open: boolean;
  onClose: () => void;
  onInvClick: () => void;
  onLogin: () => void;
  placement?: DrawerProps["placement"];
}

interface Item {
  key: string;
  header: React.ReactNode;
  content?: React.ReactNode;
  disableCollapse?: boolean;
  isHide?: boolean;
  onClick?: () => void;
}

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const HeaderDrawer: React.FC<HeaderDrawerProps> = ({
  open,
  onClose,
  onInvClick,
  onLogin,
  placement = "top",
}) => {
  const auth = useAuth(); //全局登录态
  var nav = useNavigate();
  const { activeLanguage } = useVoerkaI18n();

  const drawerStyles: DrawerStyles = {
    body: {
      padding: 0,
    },
  };

  const items: Item[] = [
    {
      key: "0",
      isHide: !auth.isLoggedIn,
      header: (
        <div style={{color:'#FF8152'}}>
          {" "}
          {t("可处理页书： ")}
          {auth.count}
        </div>
      ),
      disableCollapse: true,
    },
    {
      key: "1",
      header: t("图书转文字"),
      disableCollapse: true,
      onClick: () => {
        nav("/pdf-to-pdf-conversion");
        onClose();
      },
    },
    {
      key: "2",
      header: t("使用场景"),
      content: (
        <ScenariosFlex>
          <GridItem
            onClick={() => {
              nav("/users/corporate-and-academic-research-institutions");
              onClose();
            }}
          >
            <Image src={icon_llms} />
            <Label>{t("LLMs企业/学术研究机构")}</Label>
          </GridItem>

          <GridItem
            onClick={() => {
              nav("/users/e-book-enterprise");
              onClose();
            }}
          >
            <Image src={home_use_icon_company} />
            <Label>{t("电子书企业")}</Label>
          </GridItem>

          <GridItem
            onClick={() => {
              nav("/users/readers");
              onClose();
            }}
          >
            <Image src={home_use_icon_read} />
            <Label>{t("阅读爱好者")}</Label>
          </GridItem>

          <GridItem
            onClick={() => {
              nav("/users/students-and-teachers");
              onClose();
            }}
          >
            <Image src={home_use_icon_student} />
            <Label>{t("学生/老师")}</Label>
          </GridItem>

          <GridItem
            onClick={() => {
              nav("/users/disabled-people");
              onClose();
            }}
          >
            <Image src={home_use_icon_body} />
            <Label>{t("残障人士")}</Label>
          </GridItem>

          <GridItem
            onClick={() => {
              nav("/users/language-learners");
              onClose();
            }}
          >
            <Image src={home_use_icon_ab} />
            <Label>{t("语言学习者")}</Label>
          </GridItem>
        </ScenariosFlex>
      ),
    },
    {
      key: "9",
      header: t("API"),
      content: <div className="custom-panel">Content 3</div>,
      disableCollapse: true,
      onClick: () => {
        nav("/get-api");
        onClose();
      },
    },
    {
      key: "3",
      header: t("Q&A"),
      content: <div className="custom-panel">Content 3</div>,
      disableCollapse: true,
      onClick: () => {
        nav("/faq");
        onClose();
      },
    },
    {
      key: "4",
      header: t("Blog"),
      content: (
        <Flex align="center" vertical>
          <BlogBox />
          <MoreButton
            onClick={() => {
              nav("/articles");
              logEvent("Home", "articles_click", "articles");
              onClose();
            }}
          >
            {t("更多")}
            <MoreIcon alt="" src={arrow} />
          </MoreButton>
        </Flex>
      ),
    },
    {
      key: "5",
      header: t("定价"),
      content: <div className="custom-panel">Content 3</div>,
      disableCollapse: true,
      onClick: () => {
        nav("/pricing");
        onClose();
      },
    },
    {
      key: "6",
      header: t("处理记录"),
      content: <div className="custom-panel">Content 3</div>,
      disableCollapse: true,
      onClick: () => {
        nav("/historical");
        onClose();
      },
    },
    {
      key: "7",
      header: t("填写邀请码"),
      disableCollapse: true,
      isHide: auth.isLoggedIn,
      onClick: () => {
        onInvClick();
        onClose();
      },
    },
    {
      key: "8",
      header: (
        <Flex style={{ width: "95vw" }}>
          <div style={{ width: "100%" }}>{t("语言")}</div>
          <div style={{ width: "100px", color: "#999999" }}>
            {activeLanguage === "zh" ? "简体中文" : "English"}
          </div>
        </Flex>
      ),
      content: (
        <Flex vertical gap={32}>
          <LanguageBox
            onClick={() => {
              VoerkaI18n.change("zh");
            }}
          >
            <span>简体中文</span>{" "}
            {activeLanguage === "zh" && <Sel src={nav_more_sel} />}
          </LanguageBox>
          <LanguageBox
            onClick={() => {
              VoerkaI18n.change("en");
            }}
          >
            <span>English</span>{" "}
            {activeLanguage === "en" && <Sel src={nav_more_sel} />}
          </LanguageBox>
        </Flex>
      ),
    },
  ];

  return (
    <Drawer
      height={"100%"}
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      key={placement}
      styles={drawerStyles}
    >
      <Flex align="center" justify="center">
        <HeaderBar>
          <HeaderImage
            onClick={() => {
              onClose();
            }}
            src={logo}
            alt="logo"
          />
          <div style={{ width: "100%" }} />
          <DreawerClose
            src={h5_close}
            onClick={() => {
              onClose();
            }}
          />
        </HeaderBar>
      </Flex>

      <StyledCollapse bordered={false} expandIconPosition={"end"}>
        {items.map((item) => (
          <>
            {item.isHide ? null : (
              <Panel
                header={
                  <div
                    onClick={item.disableCollapse ? item.onClick : undefined}
                  >
                    {item.header}
                  </div>
                }
                key={item.key}
                className="custom-divider"
                showArrow={!item.disableCollapse}
                collapsible={item.disableCollapse ? "disabled" : undefined}
              >
                {!item.disableCollapse && <div>{item.content}</div>}
              </Panel>
            )}
          </>
        ))}
      </StyledCollapse>

      <center>
        <LoginButton onClick={onLogin}>
          {auth.isLoggedIn ? t("退出账号") : t("登录")}
        </LoginButton>
      </center>
    </Drawer>
  );
};

export default HeaderDrawer;

const HeaderBar = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
`;

const HeaderImage = styled.img`
  width: 280px;
  height: 90px;

  @media only screen and (max-width: 768px) {
    width: 988px;
    height: 317px;
  }

  @media only screen and (min-width: 769px) {
    width: 280px;
    height: 90px;
  }

  cursor: pointer;
`;

const DreawerClose = styled.img`
  width: 246px;
  height: 246px;

  cursor: pointer;
`;

const ScenariosFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const GridItem = styled.div`
  width: 90%;
  height: 328px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  cursor: pointer;
`;

const Image = styled.img`
  width: 123px;
  height: 123px;
`;

const Label = styled.label`
  margin-top: 8px;
  margin-left: 36px;

  font-family: Montserrat Regular;
  font-weight: 400;
  font-size: 82px;
  color: #222222;
  line-height: 97px;
  text-align: left;
  font-style: normal;
`;

const StyledCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

  .ant-collapse-item-active {
    background-color: #f8faff; /* 展开后的背景颜色 */
  }

  .ant-collapse-item {
    width: 100%;
    overflow: hidden; /* 防止内容溢出 */
  }

  .ant-collapse-header {
    height: auto; /* 设置每个 Collapse.Panel 的标题高度 */
    display: flex;
    align-items: center;
  }

  .ant-collapse-header-text {
    font-size: 82px;
    color: #222222;
    font-weight: bold;
    height: 280px; /* 设置每个 Collapse.Panel 的标题高度 */
    display: flex;
    align-items: center;
  }

  .ant-collapse-expand-icon {
    align-self: center;
  }

  .ant-collapse-content {
    height: auto;
    transition: height 0.3s ease; /* 添加过渡效果 */
  }

  .ant-collapse-content-box {
    height: auto;
  }

  .custom-panel {
    height: 280px; /* 设置默认高度 */
    display: inline-block;
  }

  .ant-collapse-content-active .custom-panel {
    height: auto; /* 展开时自动调整高度 */
  }

  .custom-divider {
    border-bottom: 1px solid #ebebeb; /* 自定义分割线样式 */
    margin: 0; /* 移除默认的外边距 */
  }
`;

const MoreButton = styled.button`
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    margin-top: 205px;
    width: 512px;
    height: 205px;
    border-radius: 41px;
    color: #373737;
  }

  @media only screen and (min-width: 769px) {
    width: 512px;
    margin-top: 80px;
    height: 205px;
    border-radius: 41px;

    margin-top: 80px;
    background: #fdc448;
    width: 180px;
    height: 60px;
    font-size: 24px;
    box-shadow: 6px 6px 0px 0px #373737;
    border-radius: 16px;
  }
`;

const MoreIcon = styled.img`
  width: 10px;
  height: 18px;
  margin-left: 8px;

  @media only screen and (max-width: 768px) {
    width: 31px;
    height: 51px;
    margin-left: 20px;
  }
`;

const LanguageBox = styled.div`
  display: flex;
  flex-direction: row;

  span {
    width: 90%;
    font-size: 82px;
    line-height: 113px;

    cursor: pointer;
  }
`;

const Sel = styled.img`
  width: 97px;
  height: 97px;
`;

const LoginButton = styled.div`
  width: 717px;
  height: 246px;
  background: #222222;
  border-radius: 41px;

  font-weight: 400;
  font-size: 82px;
  color: #ffffff;
  line-height: 246px;
  text-align: center;
  font-style: normal;
`;
