import ajax, { Format, FormatMessage } from './http'
import { AxiosRequestConfig } from 'axios'

/** 登录 */
type AnonymousUserParam = {
  device_fingerprint: string
  language: string
  ip_address: string
  country: string
}

/** 匿名创建或者登录 */
export function apiAnonymousUserLogin(param: AnonymousUserParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/anonymous_users', param)
}

/** 登录 */
type GoogleLoginParam = {
  id_token: string
  device_fingerprint: string
  language: string
  ip_address: string
  country: string
  invite_code: string | null
  official_invite_code: string | null
}

/** google 登录 */
export function apiLoginWithGoogle(param: GoogleLoginParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/login_with_google', param)
}

/** apple 登录 */
export function apiPostUserAppLoginVerifyAppleWeb(param: GoogleLoginParam) {
  return ajax.post<
  Format<{
    access_token: string
    user_id: number
  }>
>('api/apple-login', param)
}

/** Microsoft 登录 */
export function apiPostUserAppLoginVerifyMicrosoftWeb(param: GoogleLoginParam) {
  return ajax.post<
  Format<{
    access_token: string
    user_id: number
  }>
>('api/microsoft-login', param)
}

/** 上传pdf
 * ProgressCallback 进度监听
 */
interface ProgressCallback {
  (percentCompleted: number): void
}

export function apiUploadPdf(file: File, progressCallback: ProgressCallback) {
  const fd = new FormData()
  fd.append('file', file)
  return ajax.post('api/upload_pdf', fd, {
    onUploadProgress: progressEvent => {
      // 计算上传进度
      if (progressEvent.total !== undefined) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        progressCallback(Math.min(percentCompleted, 99))
        console.log(`Upload Progress: ${percentCompleted}%`)
      } else {
        console.log('Upload Progress: Unable to determine total size')
      }
    },
  })
}

/** pdf处理状态列表
 * file? 如何file不为null则为本地上传
 */
export type TaskItemData = {
  pdf_id: number
  pdf_name: string
  progress: string
  status: string
  is_downloadable: boolean
  preview_page_completed: boolean
  file?: File
}
export function apiPdfTasks() {
  return ajax.get<Format<Array<TaskItemData>>>('api/pdf_tasks')
}

/** pdf预览图 */
export function apiGetPreview(pdf_id: number) {
  return ajax.get<
    Format<{
      preview_pdf: Array<string>
      preview_png: Array<string>
    }>
  >('api/get_preview?pdf_id=' + pdf_id)
}

/** seo 获取邀请信息 */
export function apiGetPreviewDetail(pdf_id: number) {
  return ajax.get<Format<TaskItemData>>('/api/get_preview_detail?pdf_id=' + pdf_id)
}

/** 获取付款信息 */
export type PaymentInfo = {
  is_remaining_pages: boolean
  payment_gateways: Array<string>
  price_per_page: string
  pricing_code: string
  total_pages: number
  total_price: number
}
export function apiPaymentForPdf(pdf_id: number, user_language: string, is_pro?: boolean) {
  const config: AxiosRequestConfig = {
    params: {
      // 确保使用 'params' 键来包含所有查询参数
      pdf_id,
      user_language,
      is_pro,
    },
  }

  console.log(config)
  return ajax.get<Format<PaymentInfo>>('api/payment_for_pdf', config)
}

/** 创建支付意图 */
type PaymentIntent = {
  pdf_id: number
  pricing_code: string
  payment_methods: Array<string>
}

export function apiCreatePaymentIntent(param: PaymentIntent) {
  return ajax.post<
    Format<{
      client_secret: string
      payment_id: number
    }>
  >('/api/create-payment-intent', param)
}

/** 支付确认 */
export function apiConfirmPayment(payment_intent_id: string) {
  return ajax.post<
    Format<{
      amount: number
      currency: string
    }>
  >('/api/confirm-payment', { payment_intent_id })
}

/** 消耗页数 */
export function apiValidatePageConsumed(pdf_id: number) {
  return ajax.get<Format<{}>>('/api/validate-page-consumed?pdf_id=' + pdf_id)
}

/** 删除记录 */
export function apiDeletePdf(pdf_id: number) {
  return ajax.delete<Format<{}>>('/api/delete_pdf?pdf_id=' + pdf_id)
}

/** 下载PDF */
export function apiDdownloadPdf(pdf_id: number) {
  return ajax.get<
    Format<{
      compiled_pdf: string
    }>
  >('/api/download_pdf?pdf_id=' + pdf_id)
}

/** 使用优惠码 */
export function apiUsePromoCode(pdf_id: number, promo_code: string) {
  return ajax.post<Format<{}>>('/api/use-promo-code', { pdf_id, promo_code })
}

/** 开启心跳会话 */
export function apiStartSession() {
  return ajax.post<
    Format<{
      session_id: number
    }>
  >('/api/start_session')
}

/** 心跳监听 */
export function apiHeartbeat(session_id: number) {
  return ajax.post('/api/heartbeat', { session_id })
}

/** seo 文章获取 */
export type ArticleContent = {
  seo_id: number
  index: number
  title: string
  content: string
  image_path: string
  keyword_tag: string
}

export function apiArticleList(activeLanguage: string) {
  return ajax.get<Format<Array<ArticleContent>>>('/api/get-seo?language=' + activeLanguage)
}

/** seo 文章获取内容 */
export function apiArticleGet(keyword_tag: string) {
  return ajax.get<
    Format<{
      content: string
      title: string
      keywords: string
      description: string
    }>
  >('/api/get-seo-by-key?keyword_tag=' + keyword_tag)
}

/** seo 剩余文章生成次数 */
export function ApiRemainingPages() {
  return ajax.get<
    Format<{
      remaining_pages: number
    }>
  >('/api/my_remaining_pages')
}

/** seo 获取邀请信息 */
export function ApiMyinvitation() {
  return ajax.get<
    Format<{
      invite_code: string
      invite_link: string
    }>
  >('/api/my_invitation')
}

/** 验证邀请码*/
export function apiInvitionVerify(code: string) {
  return ajax.get('api/invition/verify?invitation_code=' + code)
}

/** 检测邮箱 */
export function apiCheckEmail(email: string) {
  return ajax.get<
    Format<{
      has_registered: boolean
    }>
  >('api/check_email?email=' + email)
}

/** 发送邮件 */
export function apiSendEmail(receiver: string, language: string) {
  return ajax.post('api/send_verification_code', {
    receiver,
    language,
  })
}

/** 登录 */
type EmailRegisterParam = {
  device_fingerprint: string
  language: string
  ip_address: string
  country: string
  verification_code: string | null
  invite_code: string | null
  official_invite_code: string | null
  email: string
  password: string
}
/** 邮箱注册 */
export function apiRegisterEmail(params: EmailRegisterParam) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/register_by_email', params)
}

/** 邮箱设置密码 */
export function apiSetPassword(password: string) {
  return ajax.post<Format<{}>>('api/set_password', { password })
}

/** 邮箱密码登录 */
export function apiPasswordLogin(email: string, password: string) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/login_with_email', { email, password })
}

/**通用买点接口 */
export function apiTrack(
  page_url: string,
  event_type: string,
  event_data: Object,
  tracking_name: String,
  response_status_code: Number
) {
  return ajax.post<
    Format<{
      access_token: string
      user_id: number
    }>
  >('api/track', { page_url, event_type, event_data, tracking_name, response_status_code })
}

/** 获取key和密钥*/
export function apiGetKeySecret() {
  return ajax.get('api/pdf/get_company_info')
}

/** 获取b2b测试密钥*/
export function apiGetTestKeySecret() {
  return ajax.get('api/pdf/test-client-token')
}

/**B2B创建付款详情 */
export function apiGetB2bPaymentDetail(pages: string, user_language: string) {
  const config: AxiosRequestConfig = {
    params: {
      // 确保使用 'params' 键来包含所有查询参数
      pages,
      user_language,
    },
  }
  return ajax.get('api/b2b/payment_for_pdf', config)
}

/** b2b支付意图接口 */
type B2bPaymentIntent = {
  pages: string
  pricing_code: string
  payment_methods: Array<string>
}

/**b2b创建支付意图接口 */
export function apiCreateB2bPaymentIntent(param: B2bPaymentIntent) {
  return ajax.post<
    Format<{
      client_secret: string
      payment_id: number
    }>
  >('api/b2b/create-payment-intent', param)
}

/** b2b确认付款成功 */
export function apiConfirmB2bPayment(payment_intent_id: string) {
  return ajax.post<Format<{}>>('api/b2b/confirm-payment', { payment_intent_id })
}

/**申请发票 */
export function apiApplyBill(param: any) {
  return ajax.post<Format<{}>>('api/publish_application', param)
}
