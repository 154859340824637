import styled from "styled-components";
import bar_bg from "../../../assets/home/ocr_top_bg.png";
import img_left from "../../../assets/home/ocr_top_adv@2x.png";
import { t } from "../../../languages";
import { useVoerkaI18n } from "@voerkai18n/react";
import InviateModel from "../../../components/InviateModel";
import { useEffect, useState } from "react";
import { px2vw } from "../../../libs/utils";
import { useAuth } from "../../../components/AuthContext";
import { useRequest } from "ahooks";
import { ApiMyinvitation } from "../../../libs/http/api";
import { message } from "antd";
import copy from "copy-to-clipboard";
import { CopyInviteLink, SaveInviteImage } from "../../../libs/http/tracker-api";
import { logEvent } from "../../../libs/analytics";

export default function InviateBar() {
  const { activeLanguage } = useVoerkaI18n();
  const [isModalOpen, setModalOpen] = useState(false);
  const apiInvInfo = useRequest(ApiMyinvitation, { manual:true })
  const auth = useAuth();

  useEffect(() => {
    if (auth.isLoggedIn || auth.isLocalLogin) {
      apiInvInfo.runAsync()
    }
  },[auth.isLoggedIn])

  const onInviateClick = (isUrl:boolean) => {
    if (isUrl) {
      logEvent('Home', 'inviate_click_url', "url");
      CopyInviteLink()
      copy(apiInvInfo.data?.data.data.invite_link ?? "")
      message.success(t("复制成功！"))
    }else {
      logEvent('Home', 'inviate_click_image', "image");
      SaveInviteImage()
      setModalOpen(true);
    }
  };
  return (
    <>
      <Bar>
        <ImageLeft src={img_left} />
        
        <ContentLabel language={activeLanguage ?? "en"}>
          {t("分享链接给新朋友注册，双方将获得100页Pro转换")}
        </ContentLabel>
        <InviateButton onClick={() => onInviateClick(true)}>
          {t("复制专属链接")}
        </InviateButton>
        <InviateButton onClick={() => onInviateClick(false)}>
          {t("图片分享")}
        </InviateButton>
      </Bar>

      <InviateModel code={apiInvInfo.data?.data.data.invite_code ?? ""} qrstring={apiInvInfo.data?.data.data.invite_link ?? ""} activeLanguage={activeLanguage ?? "en"} isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}

const Bar = styled.div`
  width: 100%;
  background: url(${bar_bg}) top center / 100% no-repeat;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageLeft = styled.img`
  width: 86px;
  height: 100px;
`;


const ContentLabel = styled.label<{ language:string }>`
  margin-left: 16px;
  margin-right: 16px;
  max-width: 900px;
  height: 100%;
  font-weight: bold;
  font-size: ${({ language }) => (language === "zh" ? px2vw(34) : px2vw(28))};
  color: #ffffff;
  line-height: ${({language}) => (language === "zh" ? px2vw(50) : px2vw(34))};
  text-align: center;
  font-style: normal;
`;

const InviateButton = styled.button`
  min-width: 96px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  font-size: 18px;
  color: #ef711b;
  line-height: 29px;
  text-align: center;
  font-style: normal;
  cursor: pointer;

  border: 0;
  margin: 0px 12px 0px;
`;
